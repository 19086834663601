// services/DevLoggingService.ts
import axios from 'axios';

class DevLoggingService {
  private devLoggingEnabled: boolean = false;
  private url: string = "https://dev-loggingaddin-fa.azurewebsites.net/api/HttpTrigger1?code=gqDFAsKSU6zaRGnRWRkGFXfZWAcIPPIr1YtrdshC5wbTOtwum9v8Mw==";

  constructor() {
    // Optionally initialize settings from environment or other configuration
  }

  
  setLogging(enabled: boolean): void {
    this.devLoggingEnabled = enabled;
  }

  async writeLog(object: any): Promise<void> {
    if (this.devLoggingEnabled) {
      try {
        await axios.post(this.url, JSON.stringify(object), {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        console.log("Log successfully sent to server.");
      } catch (error) {
        console.error("Failed to send log to server:", error);
      }
    }
  }
}

export default new DevLoggingService();
