"use client";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AuthenticationService from "../../services/authentication.service";
import DataService from "../../services/data.service";

interface LoginFormInputs {
  email: string;
  password: string;
}

const Loader = () => (
  <div className="flex justify-center items-center mt-10">
    <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-b-4 border-pink-500"></div>
  </div>
);

const LoginPage: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormInputs>();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [error, setError] = useState<string | null>(null);
  const [loadingSSO, setLoadingSSO] = useState(true);
  const [loadingLocal, setLoadingLocal] = useState(false);
  const [loadingAD, setLoadingAD] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    const loginSSO = async () => {
      try {
        const ssoToken = await AuthenticationService.loginSSO();
        localStorage.setItem("loginType", "sso");
        localStorage.setItem("loginToken", ssoToken.data.access_token);
        navigate("/overview");
      } catch (error) {
        console.error("SSO_ERROR", error);
        setLoadingSSO(false);
      }
    };
    setLoadingSSO(true);
    loginSSO();
  }, []);

  const onSubmit = async (data: LoginFormInputs) => {
    setSubmitted(true);
    setLoadingLocal(true);
    try {
      const result = await AuthenticationService.login(data.email, data.password);
      if (result.status === 200) {
        localStorage.setItem("loginType", "local"); 
        localStorage.setItem("loginToken", result.data.access_token);
        navigate("/overview");
      } else {
        throw new Error("Login failed");
      }
    } catch (error) {
      console.error("Login error:", error);
    } finally {
      setLoadingLocal(false);
    }
  };  

  const handleCompanyLogin = async () => {
    setLoadingAD(true);
    // Simulated redirect for demonstration
    try {
      const ssoToken = await AuthenticationService.loginSSO();
      localStorage.setItem("loginType", "sso");
      localStorage.setItem("loginToken", ssoToken.data.access_token);
      navigate("/overview");
    } catch (error) {
      console.error("SSO_ERROR", error);
      setLoadingSSO(false);
    }

    setTimeout(() => {
      setLoadingAD(false);
      // navigate('/company-login');
    }, 3000);
  };

  const setLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  return (
    <div className="container">
      {loadingSSO ? (
        <Loader />
      ) : (
        <>
          <div className="border border-border-color rounded mt-4 bg-cool-gray">
            <div className="p-5">
              {loadingLocal ? (
                <div className="loader">Loading...</div>
              ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="mb-4">
                    <input
                      type="email"
                      className={`w-full py-1.5 px-3 text-base leading-normal text-control-text bg-white rounded transition-border-shadow duration-150 ease-in-out form-control border border-border-default focus:border-border-focus focus:text-control-color focus:bg-control-bg focus:outline-none focus:shadow-control ${
                        submitted && errors.email ? "is-invalid" : ""
                      }`}
                      {...register("email", { required: t("INVALID_EMAIL"), pattern: /^\S+@\S+\.\S+$/ })}
                      placeholder={t("EMAIL")}
                    />
                    {submitted && errors.email && <div className="invalid-feedback">{t("INVALID_EMAIL")}</div>}
                  </div>
                  <div className="mb-4">
                    <input
                      type="password"
                      className={`w-full py-1.5 px-3 text-base leading-normal text-control-text bg-white rounded transition-border-shadow duration-150 ease-in-out form-control border border-border-default focus:border-border-focus focus:text-control-color focus:bg-control-bg focus:outline-none focus:shadow-control ${
                        submitted && errors.password ? "is-invalid" : ""
                      }`}
                      {...register("password", { required: t("INVALID_PASSWORD") })}
                      placeholder={t("PASSWORD")}
                    />
                    {submitted && errors.password && <div className="invalid-feedback">{t("INVALID_PASSWORD")}</div>}
                  </div>
                  <button
                    type="submit"
                    className="font-normal py-1.5 px-3 bg-primary text-white border border-primary hover:bg-primaryHover hover:border-primaryBorderHover focus:outline-none focus:ring focus:ring-offset-2 focus:ring-primary-300 focus:shadow-primaryFocus rounded transition-colors"
                  >
                    {t("LOG_IN")}
                  </button>
                </form>
              )}
            </div>
          </div>
          <h6 className="text-dark-gray my-6 font-medium text-center">{t("OR")}</h6>
          <div className="border border-border-color rounded mt-4 bg-cool-gray">
            <div className="p-5">
              {loadingAD ? (
                <div className="loader">Loading...</div>
              ) : (
                <button
                  type="button"
                  className="bg-info py-1.5 px-3 rounded text-white border border-info transition-btn duration-btn ease-btn-ease hover:bg-info-hover hover:border-info-border-hover focus:outline-none focus:ring focus:ring-info-200 focus:shadow-info-focus"
                  onClick={handleCompanyLogin}
                >
                  {t("LOGIN_COMPANY_CREDENTIALS")}
                </button>
              )}
            </div>
          </div>
          <hr className="mt-6 mb-4" />
          <div className="text-center text-muted">
            <span className="text-dark-gray cursor-pointer text-center" onClick={() => setLanguage("nl")}>
              NL
            </span>
            {" - "}
            <span className="text-dark-gray cursor-pointer text-center" onClick={() => setLanguage("fr")}>
              FR
            </span>
            {" - "}
            <span className="text-dark-gray cursor-pointer text-center" onClick={() => setLanguage("de")}>
              DE
            </span>
            {" - "}
            <span className="text-dark-gray cursor-pointer text-center" onClick={() => setLanguage("en")}>
              EN
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default LoginPage;
