import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const LogoutSSOPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const login = () => {
    navigate("/login");
  };

  return (
    <div className="container">
      <p className="mt-0 mb-4">{t("LOGOUT_SSO")}</p>
      <button
        type="button"
        className="btn inline-block font-normal text-center align-middle select-none px-3 py-1.5 text-base leading-normal rounded transition-colors duration-150 ease-in-out cursor-pointer border border-[#007bff] bg-[#007bff] text-white"
        onClick={login}
      >
        {t("LOG_IN")}
      </button>
    </div>
  );
};

export default LogoutSSOPage;
