import * as React from "react";
import NavigationPage from "./nav/navigationPage";
import AppHeader from "./header/appHeader";

interface AppProps {
  title: string;
}

const App = (props: AppProps) => {


  const title = props.title;
  // The list items are static and won't change at runtime,
  // so this should be an ordinary const, not a part of state.


  return (
    <div className="w-full px-3 mx-auto">
      <AppHeader></AppHeader>
      <NavigationPage></NavigationPage>
    </div>

  );
};

export default App;
