// services/AuthenticationService.ts
import axios from 'axios';
import DevLoggingService from './devlogging.service';

class AuthenticationService {
  private log = DevLoggingService;  
  // declare OfficeRuntime: any;

  async login(email: string, password: string) {
    const url = `${process.env.REACT_APP_BAGAAR_API_URL}oauth/token`;
    this.log.writeLog(url);
    const body = new URLSearchParams({
      username: email,
      password: password,
      client_id: process.env.REACT_APP_OASE_ADDIN_CLIENT_ID,
      client_secret: process.env.REACT_APP_OASE_ADDIN_CLIENT_SECRET,
      grant_type: 'password'
    });

    try {
      const response = await axios.post(url, body.toString(), {
        headers: {
          'Accept': '*/*',
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
      this.log.writeLog({ body: body.toString(), string: "request body" });

      
      return response;
    } catch (error) {
      this.log.writeLog(error);
      throw error;
    }
  }

  async loginSSO() {
    try {
      const localAccessToken = await OfficeRuntime.auth.getAccessToken({ allowSignInPrompt: true });

      console.log(localAccessToken)
      const url = `${process.env.REACT_APP_BAGAAR_API_URL}api/addin/token`;

      const response = await axios.post(url, {
        token: localAccessToken
      });

      console.log(response)
      return response;
    } catch (error) {
      this.log.writeLog(error);
      throw error;
    }
  }

  async getUserProfile() {
    const url = `${process.env.REACT_APP_BAGAAR_API_URL}api/users/me?include=profile`;
    const loginToken = localStorage.getItem('loginToken');
    try {
      const response = await axios.get(url, {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${loginToken}`
        }
      });
      
      return response;
    } catch (error) {
      this.log.writeLog(error);
      throw error;
    }
  }
}

export default new AuthenticationService();
