import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SettingsPage: React.FC = () => {
  const [popupSize, setPopupSize] = useState<number>(50);
  const [platformUrl, setPlatformUrl] = useState<string>("");
  const [loginType, setLoginType] = useState<string | null>(null);

  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const storedLoginType = localStorage.getItem("loginType");
    const storedPopupSize = parseInt(localStorage.getItem("popupSize") || "50", 10);
    setLoginType(storedLoginType);
    setPopupSize(storedPopupSize);
    setPlatformUrlWithToken();
  }, []);

  const setPlatformUrlWithToken = () => {
    let url = process.env.REACT_APP_OASEPLATFORM_URL || "";
    const token = localStorage.getItem("loginToken");
    if (token) {
      url += `/?token=${token}`;
    }
    setPlatformUrl(url);
  };

  const handlePopupSizeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newSize = parseInt(event.target.value, 10);
    localStorage.setItem("popupSize", newSize.toString());
    setPopupSize(newSize);
  };

  const handleLogout = () => {
    localStorage.clear();
    if (loginType !== "sso") {
      navigate("/login");
    } else {
      navigate("/logoutsso");
    }
  };

  return (
    <div className="container">
      <div className="mb-4">
        <label className="mb-2 inline-block" htmlFor="popupSize">
          {t("POPUP_SIZE")}
        </label>
        <select
          className="form-control block w-full px-3 py-1.5 text-base leading-normal text-[#495057] bg-white border border-[#ced4da] rounded transition-colors duration-150 ease-in-out"
          id="popupSize"
          value={popupSize}
          onChange={handlePopupSizeChange}
        >
          <option value="35">{t("SMALL")}</option>
          <option value="50">{t("DEFAULT")}</option>
          <option value="70">{t("BIG")}</option>
          <option value="100">{t("FULL_SCREEN")}</option>
        </select>
      </div>
      <a
        className="btn inline-block font-normal text-center align-middle select-none px-3 py-1.5 text-base leading-normal rounded transition-colors duration-150 ease-in-out cursor-pointer border border-[#007bff] bg-[#007bff] text-white"
        onClick={() => navigate("/overview")}
      >
        {t("SAVE")}
      </a>
      <button
        type="button"
        className="btn inline-block font-normal text-center align-middle whitespace-nowrap select-none px-3 py-1.5 text-base leading-normal rounded border border-[#f8f9fa] bg-[#f8f9fa] text-[#212529] transition-colors duration-150 ease-in-out cursor-pointer float-right"
        onClick={handleLogout}
      >
        {t("LOG_OUT")}
      </button>
      <hr className="mt-4 mb-4 border-0 border-t h-0 overflow-visible border-t-gray-200" />
      <p className="text-[#6c757d] font-normal text-sm mt-0 mb-4">
        {t("OTHER_SETTINGS")}{" "}
        <a href={platformUrl} target="_blank" className="text-muted">
          {t("OASE_PLATFORM")} <i className="fa fa-external-link"></i>
        </a>
      </p>
    </div>
  );
};

export default SettingsPage;
