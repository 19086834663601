import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import { Player } from "bitmovin-player";
import EngineBitmovinModule from "bitmovin-player/modules/bitmovinplayer-engine-bitmovin";
import MseRendererModule from "bitmovin-player/modules/bitmovinplayer-mserenderer";
import HlsModule from "bitmovin-player/modules/bitmovinplayer-hls";
import DashModule from "bitmovin-player/modules/bitmovinplayer-dash";
import AbrModule from "bitmovin-player/modules/bitmovinplayer-abr";
import XmlModule from "bitmovin-player/modules/bitmovinplayer-xml";
import ContainerTSModule from "bitmovin-player/modules/bitmovinplayer-container-ts";
import ContainerMp4Module from "bitmovin-player/modules/bitmovinplayer-container-mp4";
import SubtitlesModule from "bitmovin-player/modules/bitmovinplayer-subtitles";
import SubtitlesCEA608Module from "bitmovin-player/modules/bitmovinplayer-subtitles-cea608";
import PolyfillModule from "bitmovin-player/modules/bitmovinplayer-polyfill";
import StyleModule from "bitmovin-player/modules/bitmovinplayer-style";
import { UIFactory } from "bitmovin-player/bitmovinplayer-ui";
import "bitmovin-player/bitmovinplayer-ui.css";
import getLanguageFromLanguageCode from "../../../helpers/subtitleHelper";

const Popup: React.FC = () => {
  const [player, setPlayer] = useState(null);
  const playerConfig = {
    key: process.env.REACT_APP_BITMOVIN_API_KEY,
    playback: {
      autoplay: true,
      subtitleLanguage: null,
    },
    ui: false,
  };

  const playerDiv = useRef();

  useEffect(() => {
    function setupPlayer() {
      Player.addModule(EngineBitmovinModule);
      Player.addModule(MseRendererModule);
      Player.addModule(HlsModule);
      Player.addModule(XmlModule);
      Player.addModule(DashModule);
      Player.addModule(AbrModule);
      Player.addModule(ContainerTSModule);
      Player.addModule(ContainerMp4Module);
      Player.addModule(SubtitlesModule);
      Player.addModule(SubtitlesCEA608Module);
      Player.addModule(PolyfillModule);
      Player.addModule(StyleModule);

      const playerInstance = new Player(playerDiv.current, playerConfig);
      UIFactory.buildDefaultUI(playerInstance);

      const urlParams = new URLSearchParams(window.location.search);
      const id = urlParams.get("encodingId");
      const subtitlePath = urlParams.get("subpath");
      const substitleLanguageCode =
        urlParams.get("sublang") !== "" ? urlParams.get("sublang").substring(0, 2).toLowerCase() : "";

      const playerSource = {
        dash: `https://oasemedia.blob.core.windows.net/streams/${id}/manifest.mpd`,
        hls: `https://oasemedia.blob.core.windows.net/streams/${id}/manifest.m3u8`,
        skin: {
          screenLogoImage: "",
        },
        subtitleTracks: [
          {
            id: "1",
            kind: "subtitle",
            label: getLanguageFromLanguageCode(substitleLanguageCode),
            lang: substitleLanguageCode,
            url: `https:${subtitlePath}`,
          },
        ],
      };

      console.log(`https:${subtitlePath}`);
      playerInstance.load(playerSource).then(
        (player) => {
          setPlayer(player);
        },
        () => {}
      );
    }

    setupPlayer();

    return () => {
      function destroyPlayer() {
        if (player != null) {
          player.destroy();
          setPlayer(null);
        }
      }

      destroyPlayer();
    };
  }, []);

  const parentDiv = document.querySelector("#player") as HTMLElement;

  if (parentDiv) {
    const targetElement = parentDiv.querySelector(".bmpui-ui-watermark") as HTMLElement;

    if (targetElement) {
      targetElement.style.display = "none";
    }
  }

  return (
    <div id="player" ref={playerDiv} style={{ position: "relative", left: "50%", transform: "translate(-50%,-50%)" }} />
  );
};

export default Popup;
