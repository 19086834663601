import React from "react";
import logo from "../../../../assets/Oase-Full.png";
// Define the props if needed, even if they're not used yet
interface AppHeaderProps {
  // You can specify any props you plan to pass in the future here
}

class AppHeader extends React.Component<AppHeaderProps> {
  render() {
    return (
      <div className="text-center mt-12 mb-6">
        {/* You can modify the href to point to your desired URL */}
        <a href="https://oase.findwatchdo.com" title="Click to open OASE website" target="_blank">
          <img src={logo} className="inline w-3/4 border-none v-align-middle"></img>
        </a>
      </div>
    );
  }
}

export default AppHeader;
