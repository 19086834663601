import * as React from "react";
import { createHashRouter, RouterProvider, Navigate } from "react-router-dom";
import LoginPage from "../login/loginPage";
import OverviewPage from "../video/overviewPage";
import SettingsPage from "../settings/settingsPage";
import Popup from "../video/popup/popup";
import LogoutSSOPage from "../login/logoutSSOPage";

const router = createHashRouter([
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/logoutsso",
    element: <LogoutSSOPage />,
  },
  {
    path: "/overview",
    element: <OverviewPage />,
  },
  {
    path: "/popup*",
    element: <Popup />,
  },
  {
    path: "/settings",
    element: <SettingsPage />,
  },
  {
    path: "/",
    element: <Navigate to="/login" replace />,
  },
]);

const NavigationPage = () => {
  return (
    <div className="text-[#2c3e50] font-sans antialiased">
      <RouterProvider router={router} />
    </div>
  );
};

export default NavigationPage;
