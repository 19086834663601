export default function getLanguageFromLanguageCode(languageCode: string): string {
  let language: string = "English";

  if (languageCode === "de") {
    language = "Deutsch";
  } else if (languageCode === "fr") {
    language = "Français";
  } else if (languageCode === "nl") {
    language = "Nederlands";
  }

  return language;
}
