"use client";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { VideoResponseItem } from "../../../types/videoResponseItem";
import video from "../../../../../assets/type-video.png";

interface Props {
  item: VideoResponseItem;
  openPopup: () => void;
}

const VideoTipItem: React.FC<Props> = ({ item, openPopup }) => {
  // const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {}, []);

  var userLanguage = localStorage.getItem("userLanguage");
  var name = JSON.parse(item.Name)[userLanguage];

  if (!name) {
    name = JSON.parse(item.Name)["nl_BE"];
  }

  return (
    <div className="flex cursor-pointer my-1 items-center" onClick={openPopup}>
      <img src={video} className="mr-2 w-6 border-none v-align-middle align-middle"></img>
      <div>{name}</div>
    </div>
  );
};

export default VideoTipItem;
